<template>
  <div class="gender">
    <img src="/img/logo.svg" alt="Logo Instituto Burmann & Bastos" class="logo">
    <transition name="page" mode="out-in">
      <div class="d-p">
        <h1>{{ $route.path === '/tecnologias' ? 'Tecnologias' : 'O que você deseja melhorar?' }}</h1>
        <p class="text-center loading-text" v-if="loading">Carregando...</p>
        <div class="four-cols">
          <router-link v-for="area in areas" :key="area.id" :to="url + '/' + area.link" class="step-link">
            <img :src="$mediaUrl + area.image" :alt="area.title">
            {{ area.title }}
          </router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { HTTP } from '../helpers/http';

export default {
  name: 'Gender',
  components: {},
  data: () => {
    return {
      loading: true,
      areas: []
    }
  },
  props: {
    title: String,
    url: String
  },
  async created() {
    HTTP.get('lista/' + this.url)
        .then(response => {
          this.loading = false;
          this.areas = response.data.list;
        })
        .catch(err => {
          this.$snotify.error(err.response.data.message, 'Ooops');
        });
  }
}
</script>

<style scoped lang="scss">
  .gender {
    padding-top: 25px;
    text-align: center;
    position: relative;

    .logo {
      max-width: 90%;
      width: 150px;
      height: auto;
      position: relative;
    }
  }
</style>
